<template>
  <div class="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
    <div class="relative max-w-xl mx-auto">
      <svg class="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <svg class="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <div class="text-center">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Contact support
        </h2>
        <p class="mt-4 text-lg leading-6 text-gray-500">
          Your success is very important to us!
          <br>
          Let us know what's going wrong and we'll get it fixed.
        </p>
      </div>
      <div class="mt-12">
        <form v-if="formState === 'ready'" @submit="formSubmit" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div class="sm:col-span-2">
            <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
            <div class="mt-1">
              <input v-model="support.name" type="text" name="name" id="name" required="" autocomplete="given-name" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <div class="mt-1">
              <input v-model="support.from" id="email" name="email" type="email" required="" autocomplete="email" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="message" class="block text-sm font-medium text-gray-700">How can we help?</label>
            <div class="mt-1">
              <textarea v-model="support.body" id="message" name="message" required="" rows="4" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <button type="submit" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Submit
            </button>
          </div>
        </form>
        <div v-else-if="formState === 'busy'" class="mt-4 font-bold text-indigo-700">
          Submitting...
        </div>
        <div v-else-if="formState === 'error'" class="mt-4 font-bold text-indigo-700">
          <div class="mb-5 text-center text-red-700 font-medium">
            We ran into an error submitting this form.
            <br>
            Rest assured, this is not your fault!!
            <div class="my-2 text-center font-bold">
            We'll get in touch with you personally via email.
            </div>
          </div>
          <button @click="formState = 'ready'" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Try again...
          </button>
        </div>
        <div v-else class="mt-4 font-bold text-indigo-700">
          Thank you! We'll get back to you soon!!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'

export default {
  components: {
  },
  data () {
    return {
      support: {},
      formState: 'ready',
    }
  },
  methods:  {
    async formSubmit(e) { 
      e.preventDefault()
      this.formState = 'busy'
      this.support.form = 'AppSupport'
      var response = {}
      var post = JSON.parse(JSON.stringify(this.support))
      post.body += `\n[USER INFO: ${this.$auth.user.value.email} | ${this.$auth.user.value.sub} | ${this.$auth.user.value.plan.name}]`
      try {
        response = await this.axios.post(process.env.VUE_APP_FORMS_URL, this.support)
      } catch (err) {
        this.formState = 'error'
        Sentry.setContext("supportForm", this.support)
        Sentry.captureException(new Error(`Support POST: ${err}`))
        return
      }
      if (!response.data.success) {
        this.formState = 'error'
        Sentry.setContext("supportForm", this.support)
        Sentry.captureException(new Error(`Support POST: ${response.data}`))
        return
      }
      this.formState = 'done'
    }
  }
}
</script>
